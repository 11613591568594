import clsx from "classnames"
import { NodeKey } from "lexical"
import { GridBlock, GridSlot, GridSlotSize, PodcastsBlock } from "common/types/graphql"
import { FrontendBlock, ImageAspectRatio } from "modules/editor/types"
import { ContentSummaryVariant } from "common/components/ContentSummary"
import { BlockComponent } from "../BlocksRenderer"
import { ImmutableGridSlot } from "./ImmutableGridSlot"
import { MutableGridSlot } from "./MutableGridSlot"
import { imageSizes } from "common/lib/imageSizes"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"
import { ContentSummaryFrontendBlock } from "modules/editor/blocks/ContentSummary"
import type { DragItem } from "./types"

const slotSizeToContentSummaryPropsMap: Record<
  GridSlotSize,
  Partial<ContentSummaryFrontendBlock>
> = {
  [GridSlotSize.Base]: {
    imageSizes: imageSizes({ md: 0.25, sm: 0.5 }),
    variant: ContentSummaryVariant.Base,
  },
  [GridSlotSize.BaseWide]: {
    imageSizes: imageSizes({ lg: 0.125, sm: 0.25 }),
    variant: ContentSummaryVariant.BaseWide,
  },
  [GridSlotSize.Lg]: {
    imageSizes: imageSizes({ lg: 0.5, md: 1 }),
    imageAspectRatio: ImageAspectRatio.Square,
    variant: ContentSummaryVariant.Hero,
  },
  [GridSlotSize.Xl]: {
    imageSizes: imageSizes({ lg: 0.5, sm: 1 }),
    imageAspectRatio: ImageAspectRatio.FourThree,
    variant: ContentSummaryVariant.Hero,
  },
  [GridSlotSize.Xxl]: {
    imageSizes: imageSizes(),
    imageAspectRatio: ImageAspectRatio.Widescreen,
    variant: ContentSummaryVariant.Hero,
  },
  [GridSlotSize.NearFullByFour]: {
    imageSizes: imageSizes({ lg: 0.75, md: 1 }),
    imageAspectRatio: ImageAspectRatio.Square,
    variant: ContentSummaryVariant.Hero,
  },
  [GridSlotSize.NearFullByThree]: {
    imageSizes: imageSizes({ lg: 0.75, md: 1 }),
    imageAspectRatio: ImageAspectRatio.Square,
    variant: ContentSummaryVariant.Hero,
    // Text only variants that pair with this don't need that much height
    className: "lg:min-h-fit xl:min-h-fit",
  },
  [GridSlotSize.NearFullByTwo]: {
    imageSizes: imageSizes({ lg: 0.75, md: 1 }),
    imageAspectRatio: ImageAspectRatio.Square,
    variant: ContentSummaryVariant.Hero,
  },
  [GridSlotSize.DisappearingThumbnail]: {
    imageSizes: imageSizes({ lg: 0.2, sm: 0.25 }),
    variant: ContentSummaryVariant.DisappearingThumbnail,
  },
}

/**
 * Configuration for blocks based on the slot size.
 *
 * This function adds additional props to the blocks based on the slot size the slot size they are positioned.
 * These additional props can define what variant, image aspect ratio and image sizes the block should use.
 *
 * The extra props will only be passed to the block if the block is a ContentSummary or CustomURL block, otherwise
 * the block will be returned as is.
 */
const getAdjustedBlocksConfig = (
  blocks: FrontendBlock[],
  slotSizes: GridSlotSize[],
): FrontendBlock[] =>
  slotSizes.map((slotSize, index: number) => {
    const block = blocks[index] || null
    if (block?.type === "CONTENT_SUMMARY" || block?.type === "CUSTOM_URL") {
      return {
        ...block,
        ...slotSizeToContentSummaryPropsMap[slotSize],
      }
    }

    return block
  })

export interface GridProps extends Omit<GridBlock | PodcastsBlock, "blocks" | "columns"> {
  blocks: FrontendBlock[]
  slotSizes: GridSlotSize[]
  slotTypes: GridSlot[]
  nodeKey?: NodeKey
  onGridSlotClear?: (index: number) => void
  onGridSlotDrop?: (index: number, item: DragItem) => void
  onGridSlotSwap?: (a: number, b: number, item: DragItem) => void
  onGridSlotEdit?: (index: number) => void
  onGridSlotAdd?: (index: number) => void
}

export const Grid = ({
  blocks = [],
  slotSizes = [],
  slotTypes = [],
  nodeKey,
  onGridSlotClear,
  onGridSlotDrop,
  onGridSlotSwap,
  onGridSlotEdit,
  onGridSlotAdd,
}: GridProps) => {
  const { editing } = usePageProps<EditPageProps>()

  return (
    <section
      // 16px (1rem) of height comes via padding on the grid slot itself, adjust gap-y accordingly
      className={clsx(
        "grid grid-cols-3 gap-4 sm:grid-cols-12 sm:gap-6 sm:gap-y-2 xl:gap-x-8 xl:gap-y-4",
        {
          "mb-4": editing || blocks?.length > 0,
        },
      )}
      data-testid="grid"
    >
      {getAdjustedBlocksConfig(blocks, slotSizes).map((block, index) => {
        const slotType = slotTypes?.[index] || GridSlot.Editorial
        const slotSize = slotSizes[index]

        if (editing) {
          return block === null ? (
            <MutableGridSlot
              key={index}
              gridNodeKey={nodeKey}
              index={index}
              size={slotSize}
              type={slotType}
              onDrop={onGridSlotDrop}
              onSwap={onGridSlotSwap}
              onAdd={onGridSlotAdd}
              asPlaceholder
            />
          ) : (
            <MutableGridSlot
              key={index}
              gridNodeKey={nodeKey}
              index={index}
              size={slotSize}
              type={slotType}
              onClear={onGridSlotClear}
              onDrop={onGridSlotDrop}
              onSwap={onGridSlotSwap}
              onEdit={onGridSlotEdit}
            >
              <BlockComponent block={block} />
            </MutableGridSlot>
          )
        }
        return block === null ? null : (
          <ImmutableGridSlot key={index} size={slotSize} type={slotType}>
            <BlockComponent block={block} />
          </ImmutableGridSlot>
        )
      })}
    </section>
  )
}
