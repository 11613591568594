import breakpoints from "common/styles/breakpoints"

interface ImageSizesProps {
  /**
   * Ratio between image width and layout width
   */
  ratio: number
  /**
   * Ratio between image width and layout width on mobile
   */
  mobileRatio: number
}

interface ImageProportions {
  sm?: number
  md?: number
  lg?: number
  xl?: number
  "2xl"?: number
  "3xl"?: number
}

// This will "walk down" the device sizes until it finds an imageProportion, if no
// image proportion is specified it will default to 1.0
const getNearestSpecifiedProportion = (startIndex: number, imageProportions?: ImageProportions) => {
  if (imageProportions != undefined) {
    const keys = Object.keys(breakpoints)

    for (var i = startIndex; i >= 0; i--) {
      const proportion = imageProportions[keys[i] as keyof ImageProportions]
      if (proportion && typeof proportion === "number") {
        return proportion
      }
    }
  }

  return 1.0
}

// Calculate the breakpoint and proportion of display the image is expected to
// take up, the value given for "sm" will always exist as a fallback for sizes below the
// minimum supported layout width, if no values are provided, full width will be used.
//
// More about image 'sizes` attribute: https://web.dev/learn/design/responsive-images/#sizes
//
// Example usage:
//
// If on a desktop layout (3xl to md) an image takes up 50% of the layout width, and when
// on mobile (sm and below) the image takes up 100% of the layout width, call the function
// as follows:
//
//    imageSizes({ md: 0.5, sm: 1.0 })
//
// If you have an image that takes up 75% on 3xl to xl, 50% on lg to md and 100% on sm:
//
//    imageSizes({ xl: 0.75, md: 0.5, sm: 1.0 })
//
// If you always want a full width image, just call with no arguments:
//
//    imageSizes()
//
export const imageSizes = (imageProportions?: ImageProportions) => {
  const sizes = Object.entries(breakpoints).map(([deviceSize, breakpointWidth], index) => {
    const proportion = getNearestSpecifiedProportion(index, imageProportions)
    const pixelWidth = Math.ceil(breakpointWidth * proportion)

    if (deviceSize == "sm") {
      return `${pixelWidth}px`
    } else {
      return `(min-width: ${breakpointWidth}px) ${pixelWidth}px`
    }
  })

  return sizes.reverse().join(", ")
}
