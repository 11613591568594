import clsx from "classnames"
import { GridSlot, GridSlotSize } from "common/types/graphql"
import { PropsWithChildren, forwardRef, ForwardedRef } from "react"

export interface ImmutableGridSlotProps {
  /** True if the grid slot should be rendered as a placeholder, false otherwise. */
  asPlaceholder?: boolean

  /** indicates whether the grid slot is being dropped (mouse over) */
  isOver?: boolean

  /** The size of the grid slot. */
  size?: GridSlotSize

  /** The type of the grid slot. */
  type?: GridSlot
}

/** A read-only grid slot. */
export const ImmutableGridSlot = forwardRef(function ImmutableGridSlot(
  {
    size = GridSlotSize.Base,
    type = GridSlot.Editorial,
    asPlaceholder = false,
    isOver = false,
    children,
  }: PropsWithChildren<ImmutableGridSlotProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      className={clsx("group relative col-span-full flex overflow-hidden", {
        "sm:col-span-3": size === GridSlotSize.Base,
        "sm:col-span-6": size === GridSlotSize.BaseWide,
        "sm:col-span-6 row-span-2": size === GridSlotSize.Lg,
        "sm:col-span-6 row-span-4": size === GridSlotSize.Xl,
        "sm:col-span-9 row-span-2": size === GridSlotSize.NearFullByTwo,
        "sm:col-span-9 row-span-3 2xl:col-span-7": size === GridSlotSize.NearFullByThree,
        "sm:col-span-9 row-span-4 2xl:col-span-7": size === GridSlotSize.NearFullByFour,
        "sm:col-span-3 row-span-1 2xl:col-span-5": size === GridSlotSize.DisappearingThumbnail,
        "py-4": type === GridSlot.Podcast,
        "m-2.5 min-h-28 bg-blue-100 outline-dashed outline-offset-8": asPlaceholder,
        "outline-dashed outline-2 outline-offset-4 outline-gray-700": isOver,
        "outline-1 outline-blue-500": asPlaceholder && !isOver,
        "outline-2 outline-gray-700": asPlaceholder && isOver,
        "border-t pt-4": !asPlaceholder && type !== GridSlot.Podcast,
      })}
      ref={ref}
      data-testid="ImmutableGridSlot"
    >
      {children}
    </div>
  )
})
