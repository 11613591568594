// We have customised the breakpoints lg and 2xl in tailwind, refer to `common/styles/globals.css`.
//
// These values are also used to generate image sizes, `common/lib/imageSizes.ts` and are also fed
// into `next.config.js` to configure next images.
//
// Change with caution.
module.exports = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1466,
  "3xl": 2560,
}
